import React from 'react';
import { Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = { stepCount: number; currentStep: number };

const ProgressIndicator = ({ stepCount, currentStep }: Props) => {
  const percent = (currentStep / stepCount) * 100;
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Typography fontWeight={600} fontSize={14} lineHeight="150%">
        {currentStep}/{stepCount} {t('qualification:steps')}
      </Typography>
      <ProgressBar percent={percent} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: var(--embedded-text-color, inherit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const ProgressBar = styled.div<{ percent: number }>`
  height: 10px;
  background: ${(props) => props.theme.palette.grey[100]};
  position: relative;
  max-width: 380px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.palette.primary.main};
    transition: width 250ms ease;
    width: ${({ percent }) => (percent ? `${percent}%` : '0')};
  }
`;

export default ProgressIndicator;
