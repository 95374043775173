import React, { SVGProps } from 'react';

const QuestionMark = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26"
      height="41"
      viewBox="0 0 26 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0859 0C16.9893 0 20.1084 1.02181 22.4431 3.06542C24.8144 5.10904 26 7.99199 26 11.7143C26 15.1446 24.8691 17.8269 22.6073 19.761C20.382 21.6587 17.4271 22.6257 13.7425 22.6622L13.4689 26.7677H5.26076L4.98715 16.5861H8.27041C11.0794 16.5861 13.2135 16.2394 14.6728 15.5461C16.1685 14.8527 16.9163 13.5937 16.9163 11.769C16.9163 10.4918 16.5698 9.48821 15.8766 8.75835C15.1835 8.02848 14.2168 7.66355 12.9764 7.66355C11.6631 7.66355 10.6417 8.04673 9.91204 8.81308C9.18243 9.54295 8.81762 10.5465 8.81762 11.8238H0.00754772C-0.0654136 9.59769 0.390594 7.59057 1.37557 5.8024C2.39703 4.01424 3.89274 2.60926 5.86269 1.58745C7.86912 0.529151 10.2768 0 13.0859 0ZM9.47427 41C7.83264 41 6.48286 40.5256 5.42492 39.5768C4.40346 38.5915 3.89274 37.3872 3.89274 35.964C3.89274 34.5042 4.40346 33.2817 5.42492 32.2964C6.48286 31.3111 7.83264 30.8184 9.47427 30.8184C11.0794 30.8184 12.3927 31.3111 13.4142 32.2964C14.4721 33.2817 15.0011 34.5042 15.0011 35.964C15.0011 37.3872 14.4721 38.5915 13.4142 39.5768C12.3927 40.5256 11.0794 41 9.47427 41Z"
        fill="#3C4340"
      />
    </svg>
  );
};

export default QuestionMark;
