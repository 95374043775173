import React from 'react';
import { Box } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';

import { useQualification } from 'qualification/context';
import CustomerTier from 'shared/components/EmbeddedFunnel/experiments/customer_tier/CustomerTier';
import SelectedMenuCard from 'shared/components/SelectedMenu';

export default function EmbeddedCustomerTier({
  ratings,
  slides,
  LayoutComponent,
}) {
  const { t } = useTranslation();
  const { state } = useQualification();

  const selectedMenu =
    'qualification' in state ? state.qualification.selectedMenu : null;

  return (
    <Box mx={slides?.tier?.whiteWrapper && 2}>
      <LayoutComponent
        wrapper={slides?.customerTier?.whiteWrapper ? 'white' : 'transparent'}
        ratings={ratings}
        disableGutters
        bgcolor={slides?.tier?.whiteWrapper ? 'white' : 'transparent'}
        containerSize={1150}
        answerContainerSize={1150}
        borderRadius={'8px'}
        title={t('qualification:customerTier.title')}
        side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        content={<CustomerTier />}
      />
    </Box>
  );
}
