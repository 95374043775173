import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CloseCircleIcon from '@icons/close-circle.svg';
import GreenCheckmark from '@icons/green-check.svg';
import { useQualification } from 'qualification/context';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';
import { Bestseller } from 'shared/bestsellers';

const SelectedMenuCard = (props: Bestseller) => {
  const { actions } = useQualification();

  const { t } = useTranslation();

  const isMobile = useBreakpointDown('sm');

  if (isMobile) {
    return (
      <Card>
        <CloseButtonWrapper>
          <CloseButton onClick={actions.clearBestseller}>
            <CloseCircleIcon />
          </CloseButton>
        </CloseButtonWrapper>

        <Box display="flex">
          <Box display="flex" flexShrink={0}>
            <Image
              alt=""
              src={props.imageSrc}
              height={70}
              width={70}
              quality={100}
              objectFit="cover"
            />
          </Box>
          <Box
            display="flex"
            mx={2}
            textAlign="start"
            flexDirection="column"
            alignItems={'flex-start'}
            justifyContent={'center'}
          >
            <CardLabel>{t('qualification:bestseller.labelShort')}</CardLabel>
            <Title>{props.title}</Title>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <div>
      <Box py={2} pb={1} textAlign={'center'}>
        <CardLabel>{t('qualification:bestseller.label')}</CardLabel>
      </Box>
      <Card>
        <CloseButtonWrapper>
          <CloseButton onClick={actions.clearBestseller}>
            <CloseCircleIcon />
          </CloseButton>
        </CloseButtonWrapper>
        <Box mb={2}>
          <Image
            alt=""
            src={props.imageSrc}
            height={142}
            width={300}
            objectFit="cover"
          />
        </Box>
        <CardContent>
          <Box mb={1}>
            <Title>{props.title}</Title>
          </Box>
          <Box as="ul" p={0} m={0} mb={2}>
            {props.contents.map((benefit) => (
              <Box
                as="li"
                key={benefit}
                display="flex"
                alignItems={'flex-start'}
              >
                <Box mr={1}>
                  <GreenCheckmark />
                </Box>
                <Box mb={1}>{benefit}</Box>
              </Box>
            ))}
          </Box>
          <Box mt="auto" mb={1}>
            <Divider />
          </Box>
          <Box textAlign="center">
            <PriceRange>
              {props.priceRange[0]} - {props.priceRange[1]} € p.P.
            </PriceRange>
            <PriceRangeLabel>
              {t('qualification:bestseller.delivery')}
            </PriceRangeLabel>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default SelectedMenuCard;

const CardLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary[500]};
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
`;

const PriceRange = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 172.5%; /* 27.6px */
`;

const CloseButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    height: 18px;
    width: 18px;
    border-radius: 100px;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const PriceRangeLabel = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.4px;
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const CardContent = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  flex-shrink: none;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.secondary[500]};
  background-color: #f7fcfa;
  box-shadow: 0px 4px 20px 0px rgba(62, 41, 29, 0.08);
  position: relative;
  display: flex;
  height: 100%;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing(0.8)}px;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(62, 41, 29, 0.08);

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 300px;
    border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
`;

const Title = styled(Typography)`
  color: #3c4340;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%; /* 27px */

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
    text-align: center;
  }
`;
