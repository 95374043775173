import React from 'react';
import { Box } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CityValue } from 'qualification/schema/questions/cityQuestion';
import {
  Tile,
  TilesWrapper,
  TileTypography,
} from 'shared/components/Tiles/BaseComponents';
import {
  Berlin,
  Cologne,
  Dusseldorf,
  FrankfurtMain,
  Hamburg,
  Munich,
  Other,
  Stuttgart,
} from 'shared/svg/cities';

type CityIconProperty = {
  id: CityValue;
  Icon: typeof Berlin;
  translationKey: string;
};

const tileIcons: CityIconProperty[] = [
  createIconEntry('berlin', Berlin),
  createIconEntry('muenchen', Munich),
  createIconEntry(
    'frankfurt-am-main',
    FrankfurtMain,
    'common:cities.frankfurt-am-main-short'
  ),
  createIconEntry('hamburg', Hamburg),
  createIconEntry('duesseldorf', Dusseldorf),
  createIconEntry('koeln', Cologne),
  createIconEntry('stuttgart', Stuttgart),
  createIconEntry('other', Other, 'common:labels.other'),
];

type Props = {
  selected?: CityValue;
  onSelect: (city: CityValue) => void;
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm';
};

const CityTiles = ({
  onSelect,
  selected,
  variant = 'primary',
  size = 'md',
}: Props) => {
  const { t } = useTranslation();
  return (
    <TilesWrapper $size={size}>
      {tileIcons.map(({ id, Icon, translationKey }) => (
        <Tile
          $variant={variant}
          $selected={selected === id}
          $height={size === 'sm' ? '110px' : undefined}
          key={id}
          id={`tile__city--${id}`}
          onClick={(e) => {
            e.currentTarget.blur();
            onSelect(id);
          }}
        >
          <IconWrapper
            fontSize={{ xs: id === 'other' ? 30 : 50, sm: 73 }}
            my={{ xs: id === 'other' && 1, sm: 0 }}
          >
            <Icon height={size === 'sm' ? '0.6em' : '1em'} />
          </IconWrapper>
          <TileTypography>{t(translationKey)}</TileTypography>
        </Tile>
      ))}
    </TilesWrapper>
  );
};

const IconWrapper = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-grow: 1;
  }
  min-width: 50px;
  display: grid;
  place-content: center;
`;

export default CityTiles;

function createIconEntry(
  id: CityValue,
  Icon: typeof Berlin,
  translationKey?: string
): CityIconProperty {
  return { id, Icon, translationKey: translationKey || `common:cities.${id}` };
}
