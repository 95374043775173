import React from 'react';
import styled from 'styled-components';

interface Props {
  variant: 'light' | 'dark';
}

const CircularLoader = ({ variant }: Props) => {
  return (
    <CircularAnimation>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
      >
        <path
          d="M200.5 100C200.5 155.228 155.728 200 100.5 200C45.2715 200 0.5 155.228 0.5 100C0.5 44.7715 45.2715 0 100.5 0C155.728 0 200.5 44.7715 200.5 100ZM28.1509 100C28.1509 139.957 60.5427 172.349 100.5 172.349C140.457 172.349 172.849 139.957 172.849 100C172.849 60.0427 140.457 27.6509 100.5 27.6509C60.5427 27.6509 28.1509 60.0427 28.1509 100Z"
          fill={variant === 'light' ? 'white' : '#9CBA6C'}
          fillOpacity="0.2"
        />
        <path
          d="M200.5 100C200.5 86.8678 197.913 73.8642 192.888 61.7317C187.862 49.5991 180.497 38.5752 171.211 29.2893C161.925 20.0035 150.901 12.6375 138.768 7.61204C126.636 2.58657 113.632 -2.1395e-06 100.5 0V27.6509C110.001 27.6509 119.409 29.5222 128.187 33.1581C136.965 36.794 144.94 42.1232 151.659 48.8414C158.377 55.5597 163.706 63.5354 167.342 72.3132C170.978 81.091 172.849 90.499 172.849 100H200.5Z"
          fill={variant === 'light' ? 'white' : '#9CBA6C'}
        />
      </svg>
    </CircularAnimation>
  );
};

const CircularAnimation = styled.div`
  animation-name: rotate;
  animation-duration: 2.3s;
  width: auto;
  display: inline-block;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  height: 200px;
  width: 200px;

  @keyframes rotate {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default CircularLoader;
