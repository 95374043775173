import React, { ReactNode } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import styled from 'styled-components';

import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import EmbeddedQuestionControls from 'shared/components/EmbeddedFunnel/EmbeddedQuestionControls';
import SelectedAnswers from 'shared/components/EmbeddedFunnel/SelectedAnswers';
import { Ratings } from 'shared/services/trustedShops/model';
import Container, { ContainerSize } from 'system/components/Container';

// Minimal height, that avoids height changes between questions.
const MIN_HEIGHT_OVER_MD = '670px';

export interface EmbeddedQuestionLayoutProps extends Omit<BoxProps, 'title'> {
  title?: ReactNode;
  subtitle?: ReactNode;
  containerSize?: ContainerSize | number;
  content?: ReactNode;
  disableGutters?: boolean;
  answerContainerSize?: number;
  side?: ReactNode;
  wrapper?: 'white' | 'transparent';
  ratings?: Ratings;
}

const EmbeddedQuestionLayout: React.FC<EmbeddedQuestionLayoutProps> = ({
  title,
  subtitle,
  content,
  containerSize = 'sm',
  disableGutters,
  answerContainerSize = 826,
  side,
  wrapper,
  ...boxProps
}) => {
  const { state } = useQualification();

  if (
    state.status !== QUALIFICATION_STATUS.answered &&
    state.status !== QUALIFICATION_STATUS.answering &&
    state.status !== QUALIFICATION_STATUS.qualifiedForRequest
  ) {
    return null;
  }

  const step =
    state.status === QUALIFICATION_STATUS.answering
      ? state.qualification.step
      : null;
  const steps = state.qualification.steps;

  const stepIndex = step ? steps.findIndex(({ id }) => id === step.id) : -1;
  const showSelectedAnswerPills =
    state.status === QUALIFICATION_STATUS.answering && stepIndex > 0;

  return (
    <Container
      size={containerSize}
      disableGutters={disableGutters}
      {...boxProps}
    >
      <Wrapper
        bgcolor={wrapper === 'white' ? 'white' : 'transparent'}
        minHeight={{ xs: 0, md: MIN_HEIGHT_OVER_MD }}
        display="flex"
        width="100%"
        color="rgba(0,0,0,0.8)"
        flexDirection="column"
        justifyContent={'center'}
        alignItems={'flex-start'}
      >
        <WrapperBox
          width="100%"
          flexGrow={1}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          mb={4}
          alignItems={{ xs: 'center', md: 'flex-start' }}
        >
          <Box
            flexGrow={0}
            width={{ xs: '100%', md: 'min-content' }}
            maxWidth="500px"
          >
            {side}
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignSelf="stretch"
          >
            <TextWrapper>
              <Box textAlign="center" mb={2} maxWidth={550} mx={'auto'}>
                {typeof title === 'string' ? (
                  <Subtitle variant="subtitle2">{title}&nbsp;</Subtitle>
                ) : (
                  title
                )}
              </Box>
              {subtitle && (
                <Box as="p" textAlign={'center'}>
                  {subtitle}&nbsp;
                </Box>
              )}
            </TextWrapper>
            <Box my={'auto'}>{content}</Box>
            <Box mt={2}>
              <EmbeddedQuestionControls onBackground="light" />
            </Box>
          </Box>
        </WrapperBox>

        <Container
          mt="auto"
          size={answerContainerSize}
          disableGutters={disableGutters}
        >
          <Box
            bgcolor={'white'}
            borderRadius={{ xs: 0, sm: 8 }}
            visibility={showSelectedAnswerPills ? 'visible' : 'hidden'}
          >
            <SelectedAnswers currentStep={step || steps[1]} />
          </Box>
        </Container>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled(Box)`
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(3)}px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const WrapperBox = styled(Box)`
  gap: 1rem;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: 0;
  }
`;

const TextWrapper = styled.div`
  color: var(--embedded-text-color, inherit);
`;

const Subtitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  white-space: pre-line;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;

export default EmbeddedQuestionLayout;
