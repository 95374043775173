import React, { ReactNode, useCallback } from 'react';
import { Box } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BudgetQuestionWithPeopleCount from 'qualification/components/question_types/BudgetQuestionWithPeopleCount';
import DatePickerEmbedded from 'qualification/components/question_types/DatePicker/DatePickerEmbedded';
import DietaryDistribution from 'qualification/components/question_types/DietaryDistribution';
import { useQualification } from 'qualification/context';
import { StepState } from 'qualification/context/model';
import { questions } from 'qualification/schema/questions';
import { StepId, steps } from 'qualification/schema/steps';
import EmbeddedQuestionLayout, {
  EmbeddedQuestionLayoutProps,
} from 'shared/components/EmbeddedFunnel/EmbeddedQuestionLayout';
import EmbeddedCustomerTier from 'shared/components/EmbeddedFunnel/experiments/customer_tier/EmbeddedCustomerTier';
import EmbeddedCustomerRareTier from 'shared/components/EmbeddedFunnel/experiments/rare_tier/EmbeddedCustomerRareTier';
import SelectedMenuCard from 'shared/components/SelectedMenu';
import CateringCategoryTiles from 'shared/components/Tiles/CateringCategoryTiles';
import CityTiles from 'shared/components/Tiles/CityTiles';
import { Ratings } from 'shared/services/trustedShops/model';
import Container from 'system/components/Container';

const MAX_WIDTH_OF_WHITE_WRAPPER = 826;

export type StepTitleTranslationMapping = Record<StepId, string>;

export const defaultStepTitleMapping: StepTitleTranslationMapping = {
  catering_categories: 'qualification:embeddedFunnel.cateringCategories.title',
  city: 'qualification:embeddedFunnel.city.title',
  dietary_restrictions:
    'qualification:embeddedFunnel.dietaryRestrictions.title',
  event_date: 'qualification:embeddedFunnel.eventDate.title',
  people_count_and_budget: 'qualification:embeddedFunnel.budgetAndPeople.title',
  customer_tier: 'qualification:customerTier.title',
  rare_customer_tier: 'qualification:rareCustomerTier.title',
};

export interface EmbeddedSlideProps {
  city?: { whiteWrapper?: boolean };
  customerTier?: { whiteWrapper?: boolean };
  dietaryRestriction?: { whiteWrapper?: boolean };
  category?: { whiteWrapper?: boolean };
  dateAndLead?: { whiteWrapper?: boolean };
  budgetAndPeople?: { whiteWrapper?: boolean };
}

interface Props {
  step: StepState;
  highlightColor: 'primary' | 'secondary';
  slides?: EmbeddedSlideProps;
  renderTitle?: (title: string) => ReactNode;
  LayoutComponent?: React.FC<EmbeddedQuestionLayoutProps>;
  ratings?: Ratings;
  titleTranslationKeys?: StepTitleTranslationMapping;
}

const EmbeddedQuestion: React.FC<Props> = ({
  step,
  highlightColor,
  slides,
  renderTitle,
  ratings,
  titleTranslationKeys = defaultStepTitleMapping,
  LayoutComponent = EmbeddedQuestionLayout,
}) => {
  const { t } = useTranslation();

  const { state, actions, answerOf } = useQualification();

  const selectedMenu =
    'qualification' in state ? state.qualification.selectedMenu : null;

  const getTitleContent = useCallback(
    (title: string) => (renderTitle ? renderTitle(title) : title),
    []
  );

  const title = t(titleTranslationKeys[step.id]);

  switch (step.id) {
    case steps.city.id:
      return (
        <LayoutComponent
          title={getTitleContent(title)}
          ratings={ratings}
          content={
            <Container size="sm" disableGutters>
              <CityTiles
                size={selectedMenu ? 'sm' : 'md'}
                variant={highlightColor}
                selected={answerOf('city')}
                onSelect={(city) => {
                  actions.answerQuestion('city', city);
                  actions.nextStep();
                }}
              />
            </Container>
          }
          side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        />
      );
    case steps.cateringCategories.id: {
      return (
        <LayoutComponent
          wrapper={slides?.category?.whiteWrapper ? 'white' : 'transparent'}
          title={getTitleContent(title)}
          ratings={ratings}
          subtitle={t(
            'qualification:embeddedFunnel.cateringCategories.subtitle'
          )}
          content={
            <CateringCategoryTiles
              variant={highlightColor}
              selected={answerOf(questions.cateringCategories.id) || []}
              onSelect={(category) =>
                actions.answerQuestion(
                  questions.cateringCategories.id,
                  category
                )
              }
            />
          }
          side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        />
      );
    }
    case steps.dietaryRestriction.id:
      return (
        <LayoutComponent
          wrapper={
            slides?.dietaryRestriction?.whiteWrapper ? 'white' : 'transparent'
          }
          ratings={ratings}
          title={getTitleContent(title)}
          subtitle={t(
            'qualification:embeddedFunnel.dietaryRestrictions.subtitle'
          )}
          content={
            <Container size={MAX_WIDTH_OF_WHITE_WRAPPER}>
              <WhiteWrapper>
                <Box pt={2} mb={-2} pl={2} pr={2} pb={2}>
                  <DietaryDistribution />
                </Box>
              </WhiteWrapper>
            </Container>
          }
          side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        />
      );
    case steps.eventDateAndLeadType.id: {
      return (
        <LayoutComponent
          wrapper={slides?.dateAndLead?.whiteWrapper ? 'white' : 'transparent'}
          ratings={ratings}
          title={getTitleContent(title)}
          content={
            <Container size={MAX_WIDTH_OF_WHITE_WRAPPER}>
              <WhiteWrapper>
                <Box py={4}>
                  <DatePickerEmbedded />
                </Box>
              </WhiteWrapper>
            </Container>
          }
          side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        />
      );
    }
    case steps.budgetAndPeopleCount.id:
      return (
        <LayoutComponent
          wrapper={
            slides?.budgetAndPeople?.whiteWrapper ? 'white' : 'transparent'
          }
          ratings={ratings}
          title={getTitleContent(title)}
          content={
            <Container size={MAX_WIDTH_OF_WHITE_WRAPPER}>
              <WhiteWrapper>
                <Box py={4}>
                  <BudgetQuestionWithPeopleCount />
                </Box>
              </WhiteWrapper>
            </Container>
          }
          side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        />
      );
    case steps.customerTier.id:
      return (
        <EmbeddedCustomerTier
          ratings={ratings}
          slides={slides}
          LayoutComponent={LayoutComponent}
        />
      );
    case steps.rareCustomerTierStep.id:
      return (
        <EmbeddedCustomerRareTier
          ratings={ratings}
          slides={slides}
          LayoutComponent={LayoutComponent}
        />
      );
    default: {
      const _exhaustiveCheck: never = step.id;
      return null;
    }
  }
};

const WhiteWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1px 16px;
  width: 100%;
  color: black;
`;

export default EmbeddedQuestion;
