import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PillLabel from 'qualification/components/PillLabel';
import { StepState } from 'qualification/context/model';
import { useAnswerPills } from 'qualification/hooks/useAnswerPills';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

interface Props {
  currentStep: StepState;
  wrap?: boolean;
}

function SelectedAnswers({ currentStep, wrap }: Props) {
  const { t } = useTranslation('qualification');
  const isMobile = useBreakpointDown('xs');
  const scrollableBoxRef = useRef<null | HTMLDivElement>(null);

  const pills = useAnswerPills({ display: 'previous-answers' });

  useEffect(() => {
    function scrollLatestElementIntoView() {
      if (!scrollableBoxRef.current) {
        return;
      }
      const horizontalScrollAmount = scrollableBoxRef.current.scrollWidth;
      if (!horizontalScrollAmount) {
        return;
      }

      scrollableBoxRef.current.scroll({
        left: horizontalScrollAmount,
        behavior: 'smooth',
      });
    }

    scrollLatestElementIntoView();
  }, [currentStep, scrollableBoxRef]);

  if (!currentStep) return null;

  if (isMobile) {
    return (
      <Box display="flex" alignItems="center" pt={0.5} pb={0.5}>
        <Box flexShrink={0}>
          <TypographyYourSelection pl={2} variant="caption" fontSize={10}>
            {t('selection.yourSelection')}
          </TypographyYourSelection>
        </Box>

        <HorrizontallyScrollableBox
          ref={scrollableBoxRef}
          display="flex"
          flexWrap={wrap ? 'wrap' : 'nowrap'}
          alignItems="center"
        >
          <PillsWrapperBox mx={2} display="flex" gap={1}>
            {pills.map((pill) => (
              <PillLabel
                size="sm"
                key={pill.questionId}
                stepId={pill.stepId}
                questionId={pill.questionId}
                text={pill.text || ''}
              />
            ))}
          </PillsWrapperBox>
        </HorrizontallyScrollableBox>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" pt={0.5} pb={0.5}>
      <Box flexShrink={0}>
        <TypographyYourSelection
          variant="caption"
          pl={2}
          pr={1.5}
          fontSize={10}
        >
          {t('selection.yourSelection')}
        </TypographyYourSelection>
      </Box>
      <HorrizontallyScrollableBox
        display="flex"
        alignItems="center"
        flexWrap={wrap ? 'wrap' : 'nowrap'}
        ref={scrollableBoxRef}
      >
        <PillsWrapperBox display="flex" gap={0.75}>
          {pills.map((pill) => (
            <PillLabel
              size="sm"
              key={pill.questionId}
              stepId={pill.stepId}
              questionId={pill.questionId}
              text={pill.text || ''}
            />
          ))}
        </PillsWrapperBox>
      </HorrizontallyScrollableBox>
    </Box>
  );
}

const PillsWrapperBox = styled(Box)<{ gap: number }>`
  gap: ${(props) => props.gap || 1}rem;
  border-radius: 2rem;
  margin-left: 0px;
  margin-right: 0px;
`;

const TypographyYourSelection = styled(Typography)`
  font-weight: 400;
  flex-shrink: 0;
  color: initial;
`;

export const StyledPillar = styled.button`
  display: inline-flex;
  flex-wrap: nowrap;
  border: none;
  height: 2px;
  flex-direction: row;
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing(1, 2)};
  gap: ${(props) => props.theme.spacing(0.5)}px;
  background: #f0f0f0;
  border-radius: 66px;
  white-space: nowrap;
  cursor: pointer;

  transition: color 0.2s, background-color 0.2s;

  :hover,
  :active,
  :focus {
    background-color: ${(props) => props.theme.palette.grey.secondary};
    color: white;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(0.5, 1)};
  }
`;

const HorrizontallyScrollableBox = styled(Box)`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bec4c4;
    border-radius: 5px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default SelectedAnswers;
