import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useQualifyUser } from 'qualification/hooks/useQualifyUser';
import CircularLoader from 'shared/components/EmbeddedFunnel/CircularLoader';
import EmbeddedQuestionLayout from 'shared/components/EmbeddedFunnel/EmbeddedQuestionLayout';

interface Props {
  variant: 'light' | 'dark';
}

const LoadingIndicator = ({ variant }: Props) => {
  const { t } = useTranslation();

  useQualifyUser();

  return (
    <EmbeddedQuestionLayout
      wrapper="transparent"
      content={
        <WrapperBox textAlign="center">
          <CircularLoader variant={variant} />
          <Typography mt={3} mb={1}>
            {t('qualification:findingCaterers.listItem1')}
          </Typography>
          <Typography mb={1}>
            {t('qualification:findingCaterers.listItem2')}
          </Typography>
          <Typography>
            {t('qualification:findingCaterers.listItem3')}
          </Typography>
        </WrapperBox>
      }
    />
  );
};

const WrapperBox = styled(Box)`
  color: var(--embedded-text-color, inherit);
`;

export default LoadingIndicator;
