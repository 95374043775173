import React from 'react';
import { Box } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';

import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import { useQualification } from 'qualification/context';
import CustomerRareTier from 'shared/components/EmbeddedFunnel/experiments/rare_tier/CustomerRareTier';
import SelectedMenuCard from 'shared/components/SelectedMenu';

export default function CustomerTierRare({ ratings, slides, LayoutComponent }) {
  const { state } = useQualification();
  const { t } = useTranslation();

  const selectedMenu =
    'qualification' in state ? state.qualification.selectedMenu : null;

  return (
    <Box mx={slides?.tier?.whiteWrapper && 2}>
      <LayoutComponent
        wrapper={slides?.customerTier?.whiteWrapper ? 'white' : 'transparent'}
        ratings={ratings}
        disableGutters
        bgcolor={slides?.tier?.whiteWrapper ? 'white' : 'transparent'}
        containerSize={1150}
        answerContainerSize={800}
        borderRadius={'8px'}
        title={
          isVariationActive(EXPERIMENT.customerTierABC.variations.variationB)
            ? t('qualification:rareCustomerTier.v1.title')
            : null
        }
        side={selectedMenu && <SelectedMenuCard {...selectedMenu} />}
        content={<CustomerRareTier />}
      />
    </Box>
  );
}
